<template>
  <modal
    class="members-modal modal-without-footer"
    name="members-modal"
    @closed="$emit('updateComparisonData')"
    @before-open="beforeOpen"
    transition="fade"
    :adaptive="true"
    :scrollable="true"
    :reset="true"
    width="60%"
    height="80%"
    style="z-index: 2011;"
  >
    <div id="modal-header">
      <div class="w-75 d-flex">
        <p>Մասնակիցներ</p>
      </div>
      <button type="button"
            class="close position-absolute"
            style="right: 1.25rem; top: 25%"
            @click="$modal.hide('members-modal')"
            aria-label="Close"
      >
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 1.64102L14.859 0L8.49997 6.359L2.14102 4.62582e-05L0.5 1.64107L6.85895 8.00002L0.500034 14.3589L2.14105 16L8.49997 9.64104L14.8589 16L16.5 14.359L10.141 8.00002L16.5 1.64102Z" fill="#ABAEB8"/>
        </svg>
      </button>
    </div>
    <div id="modal-body">
      <div class="list-group">
        <span v-for="member in members" :key="member.id" class="list-group-item list-group-item-action d-flex justify-content-between">
          «{{member.user.name.hy}}» {{member.user.organisation.company_type.hy}}
          <a :href="member.additional_file" target="_blank">
            <button aria-expanded="false" type="button" class="btn btn-primary">
              <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.23096 9.01613V0.5H8.67326V9.01613L11.3656 6.30645L12.4233 7.37097L8.96173 10.7581L7.90403 11.8226L6.9425 10.7581L3.48096 7.37097L4.53865 6.30645L7.23096 9.01613ZM13.9615 13.9516V11.9193H15.5V13.758C15.5 14.7258 14.7308 15.5 13.7692 15.5H2.23077C1.26923 15.5 0.5 14.7258 0.5 13.758V11.9193H2.03846V13.9516H13.9615Z" fill="#F0F7FF"/>
              </svg>
              <span>Բեռնել</span>
            </button>
          </a>
        </span>
      </div>
    </div>
  </modal>
</template>

<script>

export default {
  name: 'ReportModal',
  data () {
    return {
      tenderData: {},
      members: [],
      tender: '',
      isLoading: false,
      customerName: '',
    }
  },
  computed: {
    me() {
      return this.$store.getters['user/me']
    },
  },
  methods: {
    download() {
      this.sendToPDFGeneration(this, this.$refs.html2Pdf.$el.innerHTML, 'Պայմանագիր', () => {this.isLoading = false})
    },
    consoleLog(data){
      console.log(data)
    },
    beforeOpen({params}) {
        this.tenderData = params;
        this.$client.get(`tender/getSuggestions/${this.tenderData.id}`).then(({data}) => {
          this.members = data
        })
    },
  }
}
</script>

<style scoped>

</style>

